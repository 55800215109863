<div class="popup-content">
  <form [formGroup]="form">
    <cap-dropdown
      appendTo="body"
      formControlName="cancel_reason_base_data_value_id"
      [filter]="true"
      [label]="'dxp.lot.cancel_reason_base_data_value_id.label' | translate"
      [options]="cancelReasonOptions$ | async"
      [placeholder]="'dxp.lot.cancel_reason_base_data_value_id.label' | translate">
    </cap-dropdown>
  </form>

  @if (showWarning && warnings.length) {
    <div class="d-flex justify-content-center mt-3">
      @for (warning of warnings; track warning) {
        <div>
          @if (warning?.message?.length) {
            <h3>
              <i class="warning mr-2 fas fa-exclamation-triangle"></i>
              <div>{{ warning.message }}</div>
            </h3>
          }
        </div>
      }
    </div>
  }
</div>

<div class="popup-actions">
  <cap-button
    styleClass="secondary mt-3"
    [attr.data-test]="'cancel-changes-button'"
    [label]="'button.cancel' | translate"
    (onClick)="onClose()"></cap-button>

  <cap-button
    appPreventDoubleClick
    [styleClass]="
      showWarning && warnings[0]?.message?.length !== 0 && errors?.length === 0
        ? 'danger ml-2 mt-3'
        : 'primary ml-2 mt-3'
    "
    [disabled]="errors?.length > 0"
    [class.pe-none]="errors?.length > 0"
    [attr.data-test]="'submit-cancel-lot-button'"
    [label]="'dxp.button.confirm' | translate"
    (clickEvent)="onSave(showWarning)">
  </cap-button>
</div>
