import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, inject, OnInit } from '@angular/core';
import { LotService } from '@core/api/lot.service';
import { ListRendererService } from '@capturum/builders/list-renderer';
import { SharedModule } from '@shared/shared.module';
import { AsyncPipe } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BaseKey } from '@core/enums/base-key.enum';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-lot',
  standalone: true,
  imports: [SharedModule, AsyncPipe, ReactiveFormsModule],
  templateUrl: './cancel-lot.component.html',
  styleUrls: ['./cancel-lot.component.scss'],
})
export class CancelLotComponent implements OnInit {
  public lotId: string;
  public cancelReasonOptions$: Observable<MapItem[]>;
  public warnings: { message: string }[] = [];
  public errors: string[];
  public showWarning = false;
  public form: FormGroup;
  private dialogRef = inject(DynamicDialogRef);
  private config = inject(DynamicDialogConfig);
  private formBuilder = inject(FormBuilder);
  private listRendererService = inject(ListRendererService);
  private baseDataService = inject(DxpIndexableBaseDataService);
  private lotService = inject(LotService);
  private notificationService = inject(NotificationService);
  private translateService = inject(TranslateService);

  public ngOnInit(): void {
    this.createForm();

    this.lotId = this.config.data.item.id;
    this.cancelReasonOptions$ = this.baseDataService.getBaseDataValuesByDataKey(BaseKey.lotCancelReasons);
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSave(force?: boolean): void {
    this.warnings = [];
    this.errors = [];
    const payload = {
      cancel_reason_base_data_value_id: this.form.value.cancel_reason_base_data_value_id,
      _force: force,
    };

    this.lotService.cancelLot(this.lotId, payload).subscribe({
      next: () => {
        this.notificationService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('dxp.lots.cancel-lot.success.message'),
        );

        this.onClose();
        this.listRendererService.refreshTable();
      },
      error: ({ error }) => {
        if (error?.warnings) {
          this.showWarning = true;
          this.warnings = [...this.warnings, { message: error.warnings }];
        }

        if (error?.errors) {
          this.errors = error.errors;
        }
      },
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      cancel_reason_base_data_value_id: [null, Validators.required],
    });
  }
}
